// Vneodrs
import { Injectable } from '@angular/core';

// Config
import { AppConfig } from '../config/app.config';

@Injectable({ providedIn: 'root' })
export class LocalStorageManager {
    public tokenName: string = AppConfig.token;

    constructor() { }

    public setItem(name: string, value: any): void {
        localStorage.setItem(name, JSON.stringify(value));
    }

    public setToken(token: string): void {
        this.setItem(this.tokenName, token);
    }

    public getItem(name: string): any {
        if (!localStorage.getItem(name) || localStorage.getItem(name) === 'undefined') {
            return null
        }

        const data = JSON.parse(localStorage.getItem(name)!);

        return data;
    }

    public isAuthenticated(): boolean {
        const token = this.getItem(this.tokenName);

        if (token) {
            return true;
        } else {
            return false;
        }
    }

    public removeItem(name: string): void {
        localStorage.removeItem(name);
    }
}
