import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { VisaCardModel } from "../models/cards/visa-card.model";
import { PaginationModel } from "../models/pagination.model";
import { ApiConfig } from "../config/api.config";
import { environment } from "../../../environments/environment";

@Injectable()
export class VisaCardService {
  constructor(private _http: HttpClient) {}

  public getCards(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    IncludeArchived: boolean
  ): Observable<PaginationModel<VisaCardModel[]>> {
    let request = ApiConfig.visaCards.get;
    request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}&pageCount=${pageCount}&search=${search}&IncludeArchived=${IncludeArchived}`;

    return this._http.get<PaginationModel<VisaCardModel[]>>(
      `${environment.url}${request}`
    );
  }
}
