import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BuildingDetailsModel } from "../models";
import { PaginationModel } from "../models/pagination.model";
import { ApiConfig } from "../config/api.config";
import { environment } from "../../../environments/environment";
import {
  ApplicationResultModel,
  BuildingAdminModel,
  CreateBuildingModel,
  UpdateBuildingModel,
} from "../models";

@Injectable()
export class BuildingService {
  constructor(private _http: HttpClient) {}

  public getBuidings(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    IncludeArchived: boolean,
    IncludeOnlyArchived: boolean
  ): Observable<PaginationModel<BuildingDetailsModel[]>> {
    let request = ApiConfig.building.get;
    request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}&pageCount=${pageCount}&search=${search}&IncludeArchived=${IncludeArchived}&IncludeOnlyArchived=${IncludeOnlyArchived}`;

    return this._http.get<PaginationModel<BuildingDetailsModel[]>>(
      `${environment.url}${request}`
    );
  }

  public getById(id: string): Observable<BuildingAdminModel> {
    const request = `${ApiConfig.building.byId}?id=${id}`;

    return this._http.get<BuildingAdminModel>(`${environment.url}${request}`);
  }

  public update(data: UpdateBuildingModel): Observable<ApplicationResultModel> {
    const request = ApiConfig.building.update;

    return this._http.put<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public createBuilding(
    data: CreateBuildingModel
  ): Observable<BuildingAdminModel> {
    const request = ApiConfig.building.create;

    return this._http.post<BuildingAdminModel>(
      `${environment.url}${request}`,
      data
    );
  }
}
