// Vendors
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// Managers
import { LocalStorageManager } from '../managers';
// helpers
import { NavHelper } from '../helpers';

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private _lsManager: LocalStorageManager,
    private _navHelper: NavHelper
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    console.log(this._lsManager.isAuthenticated());
    
    if (
      this._lsManager.isAuthenticated()
    ) {
      let token = '';

      if (this._lsManager.getItem(this._lsManager.tokenName)) {
        token = this._lsManager.getItem(this._lsManager.tokenName);
      }
      console.log(token);
      
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next
      .handle(request)
      .pipe(catchError((error: any) => this.handleAuthError(error)));
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {   
    if (error.status == 401 || error.status == 0) {
      this._navHelper.goToLogin();
    }

    return throwError(error);
  }
}
