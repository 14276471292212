import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Brands",
    url: "/brands",
    iconComponent: { name: "cil-align-left" },
    children: [
      {
        name: "Active",
        url: "/brands/active",
      },
      {
        name: "Archive",
        url: "/brands/archive",
      },
    ],
  },
  {
    name: "Offers",
    url: "/offers",
    iconComponent: { name: "cil-applications" },
    children: [
      {
        name: "Common",
        url: "/offers/common",
        children: [
          {
            name: "Active",
            url: "/offers/common/active",
          },
          {
            name: "Archive",
            url: "/offers/common/archive",
          },
        ],
      },
      {
        name: "Premium",
        url: "/offers/premium",
        children: [
          {
            name: "Active",
            url: "/offers/premium/active",
          },
          {
            name: "Archive",
            url: "/offers/premium/archive",
          },
        ],
      },
    ],
  },
  // {
  //   name: 'Banners',
  //   url: '/banners',
  //   iconComponent: { name: 'cil-contact' }
  // },
  {
    name: "Categories",
    url: "/categories",
    iconComponent: { name: "cil-badge" },
    children: [
      {
        name: "Common",
        url: "/categories/common",
        children: [
          {
            name: "Active",
            url: "/categories/common/active",
          },
          {
            name: "Archive",
            url: "/categories/common/archive",
          },
        ],
      },
      {
        name: "Premium",
        url: "/categories/premium",
        children: [
          {
            name: "Active",
            url: "/categories/premium/active",
          },
          {
            name: "Archive",
            url: "/categories/premium/archive",
          },
        ],
      },
    ],
  },
  {
    name: "Buildings",
    url: "/buildings",
    iconComponent: { name: "cil-door" },
    children: [
      {
        name: "Buildings",
        url: "/buildings/all",
      },
      {
        name: "Map Preview",
        url: "/buildings/preview",
      },
    ],
  },
  {
    name: "Partners",
    url: "/partners",
    iconComponent: { name: "cil-contact" },
  },
  {
    name: "Feedbacks",
    url: "/feedbacks",
    iconComponent: { name: "cil-contact" },
  },
];
