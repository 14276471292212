import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PaginationModel, SupportMailModel } from "../models";
import { ApiConfig } from "../config/api.config";
import { environment } from "src/environments/environment";
@Injectable()
export class SupportService {
  constructor(private _http: HttpClient) {}

  public getFeedbacks(
    includeArchived: boolean,
    includeOnlyArchived: boolean,
    search: string,
    pageNumber: number,
    pageSize: number,
    pageCount: number
  ): Observable<PaginationModel<SupportMailModel[]>> {
    let request = ApiConfig.support.get;

    request = `${request}?IncludeArchived=${includeArchived}&IncludeOnlyArchived=${includeOnlyArchived}&Search=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}&PageCount=${pageCount}`;

    return this._http.get<PaginationModel<SupportMailModel[]>>(
      `${environment.url}${request}`
    );
  }
}
