import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  AdminBannerModel,
  ApplicationResultModel,
  BannerModel,
  CreateBannerCommandModel,
  PaginationModel,
  UpdateBannerCommandModel,
} from "../models";
import { ApiConfig } from "../config/api.config";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class BannerService {
  constructor(private _http: HttpClient) {}

  public getBanners(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    IncludeArchived: boolean,
    IncludeOnlyArchived: boolean
  ): Observable<PaginationModel<BannerModel[]>> {
    let request = ApiConfig.banners.get;
    request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}&pageCount=${pageCount}&search=${search}&IncludeArchived=${IncludeArchived}&IncludeOnlyArchived=${IncludeOnlyArchived}`;
    return this._http.get<PaginationModel<BannerModel[]>>(
      `${environment.url}${request}`
    );
  }

  public createBanner(
    data: CreateBannerCommandModel
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.banners.create;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public getById(id: string): Observable<AdminBannerModel> {
    const request = `${ApiConfig.banners.byId}?id=${id}`;

    return this._http.get<AdminBannerModel>(`${environment.url}${request}`);
  }

  public updateBanner(
    data: UpdateBannerCommandModel
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.banners.update;

    return this._http.put<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public deleteBanner(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.banners.delete;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        id,
      },
    };

    return this._http.delete<ApplicationResultModel>(
      `${environment.url}${request}`,
      options
    );
  }

  public archive(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.banners.archive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id }
    );
  }

  public unarchive(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.banners.unarchive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id }
    );
  }
}
