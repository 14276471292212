import { ELanguage } from "../enums/language.enum";

export class AppConfig {
  static token: string = "visa-admin";
  static languages = [
    { title: "Ru", key: ELanguage["russian"] },
    { title: "Az", key: ELanguage["azerbaijani"] },
  ];

  static sectorKeys = ['H', 'S', 'W', 'O'];
  static numberInSector = ['6_2', '4_3', '4_2', '2_1', '6_1', '3_1', '4_1', '1_1', '1_2', '5_1', '8_1', '9_1', '7_1', '10_1', '1_3', '3_2', '13_1', '2_2', '3_4', '3_3', '12_1', '11_1'];
  static buildingModelType = ['H6', 'H4', 'H2', 'H3', 'H1', 'H5', 'S5', 'S4', 'S3', 'S1', 'S8', 'W6', 'W9', 'S6', 'W7', 'W8', 'W4', 'W1', 'W10', 'O1', 'W5', 'O3', 'W13', 'S7', 'W2', 'W3', 'O2', 'W12', 'W11', 'S2', 'S10', 'O7', 'O4', 'O6', 'S9', 'O5'];

  static regularObjects = [
    "house2_model",
    "house3_model",
    "house4_model",
    "house5_model",
    "house6_model",
    "house7_model",
    "house8_model",
    "house9_model",
    "house10_model",
    "house11_model",
    "signature_abqora",
    "signature_hitech_playground",
    "signature_sea_breeze",
    "signature_white_city_1",
    "signature_white_city_2",
  ];

  static replacementObjects = [
    "ground_play",
    "ground_football",
    "park_big_base",
    "park_small_base",
  ];

  static uvs = [
    {
      name: 'uv0',
      uv: 0,
    },
    {
      name: 'uv1',
      uv: 1,
    },
    {
      name: 'uv2',
      uv: 3,
    },
    {
      name: 'uv3',
      uv: 3,
    },
  ]

  static maxBrandName: number = 60;
  static maxOfferName: number = 60;
  static maxCategoryName: number = 60;
  static newItemId: string = "New";

  static errorTimer: number = 4000;

  static dropdownPageSize: number = 20;
}
