import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  AdminBrandModel,
  BrandModel,
  PaginationModel,
  ApplicationResultModel,
  UpdateBrandModel,
} from "../models";
import { ApiConfig } from "../config/api.config";
import { environment } from "../../../environments/environment";
import { ESortTypes } from "../enums";

@Injectable()
export class BrandService {
  constructor(private _http: HttpClient) {}

  public archiveBrand(brandId: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.brands.acrhive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id: brandId }
    );
  }

  public unarchiveBrand(brandId: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.brands.unarchive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id: brandId }
    );
  }

  public createBrand(name: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.brands.create;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { name }
    );
  }

  public setImage(
    id: string,
    image: File
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.brands.setImage;
    const fd = new FormData();
    fd.append("id", id);
    fd.append("image", image);
    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      fd
    );
  }

  public getByName(name: string): Observable<AdminBrandModel> {
    const request = `${ApiConfig.brands.getByName}?name=${name}`;

    return this._http.get<AdminBrandModel>(`${environment.url}${request}`);
  }

  public editBrand(data: UpdateBrandModel): Observable<ApplicationResultModel> {
    const request = ApiConfig.brands.update;

    return this._http.put<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public getBrands(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    IncludeArchived: boolean,
    IncludeOnlyArchived: boolean,
    IncludeOnlyWithOffers: boolean,
    IsSortedDesc: boolean = false,
    SortedBy: ESortTypes = ESortTypes['name'],
  ): Observable<PaginationModel<BrandModel[]>> {
    let request = ApiConfig.brands.get;
    request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}&pageCount=${pageCount}&search=${search}&IncludeArchived=${IncludeArchived}&IncludeOnlyArchived=${IncludeOnlyArchived}&IncludeOnlyWithOffers=${IncludeOnlyWithOffers}&SortedBy=${SortedBy}&IsSortedDesc=${IsSortedDesc}`;
    return this._http.get<PaginationModel<BrandModel[]>>(
      `${environment.url}${request}`
    );
  }

  public getById(id: string): Observable<AdminBrandModel> {
    const request = `${ApiConfig.brands.byid}?id=${id}`;

    return this._http.get<AdminBrandModel>(`${environment.url}${request}`);
  }
}
