// Vendors
import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { NavHelper } from "src/app/shared/helpers";
import { LocalStorageManager } from "src/app/shared/managers";
import { AuthService } from "src/app/shared/service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private _timeout: any;
  public form: FormGroup;
  public isSubmit: boolean = false;
  public isLogin: boolean = false;

  constructor(
    private _authService: AuthService,
    private _navHelper: NavHelper,
    private _localStorageManager: LocalStorageManager
  ) {
    this._initForm();

    this._subscriptions.add(this.form.valueChanges.subscribe((res) => {
      this.hideErrorsImmidiatly();
    }))
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    clearTimeout(this._timeout);
  }

  public hideErrors(): void {
    this._timeout = setTimeout(() => {
      this.hideErrorsImmidiatly();
    }, 3000);
  }

  public hideErrorsImmidiatly(): void {
    if (this.form.get('email')?.hasError('invalid_data')) {
      this.form.get('email')?.setErrors(null);
    }
    clearTimeout(this._timeout);
    this.isSubmit = false;
  }

  public login(): void {
    if (this.isLogin) {
      return;
    }

    this.isSubmit = true;
    const form = this.form;
    
    if (form.valid) {
      this.isLogin = true;
      this.isSubmit = false;

      this._subscriptions.add(
        this._authService
          .login(form.get("email")?.value, form.get("password")?.value)
          .subscribe((res: any) => {
            this._localStorageManager.setItem(
              this._localStorageManager.tokenName,
              res.token
            );
            this._navHelper.goToBrands();
          }, (err: any) => {
            this.isLogin = false;
            this.isSubmit = true;
            this.form.get('email')?.setErrors({invalid_data: true});
            
            this.hideErrors();
          })
      );
    } else {
      this.hideErrors();
    }
  }

  private _initForm(): void {
    this.form = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });
  }
}
