import { Injectable } from "@angular/core";
import { ELanguage } from "../enums/language.enum";
import { Observable } from "rxjs";
import { PaginationModel, CategoryModel } from "../models";
import { ApiConfig } from "../config/api.config";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {
  ApplicationResultModel,
  CategoryAdminModel,
  CreateCategoryModel,
  UpdateCategoryModel,
} from "../models";
import { ECategoryImageType } from "../enums";

@Injectable()
export class CategoryService {
  constructor(private _http: HttpClient) {}

  public getCategories(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    IncludeArchived: boolean,
    IncludeOnlyArchived: boolean,
    isPremium: boolean,
    language: ELanguage
  ): Observable<PaginationModel<CategoryModel[]>> {
    let request = ApiConfig.categories.get;

    if (search) {
      request = `${request}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    } else {
      request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    request = `${request}&IncludeArchived=${IncludeArchived}&IsPrivilege=${isPremium}&IncludeOnlyArchived=${IncludeOnlyArchived}&pageCount=${pageCount}&language=${language}`;

    return this._http.get<PaginationModel<CategoryModel[]>>(
      `${environment.url}${request}`
    );
  }

  public createCategory(
    data: CreateCategoryModel
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.categories.create;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public byName(
    language: ELanguage,
    name: string
  ): Observable<CategoryAdminModel> {
    const request = ApiConfig.categories.byName;

    return this._http.get<CategoryAdminModel>(
      `${environment.url}${request}?language=${language}&name=${name}`
    );
  }

  public byId(id: string): Observable<CategoryAdminModel> {
    const request = ApiConfig.categories.byId;

    return this._http.get<CategoryAdminModel>(
      `${environment.url}${request}?id=${id}`
    );
  }

  public update(data: UpdateCategoryModel): Observable<ApplicationResultModel> {
    const request = ApiConfig.categories.update;

    return this._http.put<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public archive(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.categories.archive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id }
    );
  }

  public unarchive(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.categories.unarchive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id }
    );
  }

  public setImage(
    imageType: ECategoryImageType,
    id: string,
    image: File
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.categories.setImage;
    const fd = new FormData();
    fd.append("ImageType", `${imageType}`);
    fd.append("Id", id);
    fd.append("Image", image);

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      fd
    );
  }
}
