import { Injectable } from "@angular/core";
import { ApiConfig } from "../config/api.config";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import {
  PaginationModel,
  ReplacementObjectModel,
  ReplacementObjectAdminModel,
} from "../models";
@Injectable()
export class ReplacementObjectService {
  constructor(private _http: HttpClient) {}

  public getReplacementObjects(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    IncludeArchived: boolean,
    IncludeOnlyArchived: boolean
  ): Observable<PaginationModel<ReplacementObjectModel[]>> {
    let request = ApiConfig.replacementObject.get;
    if (search) {
      request = `${request}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    } else {
      request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    request = `${request}&IncludeArchived=${IncludeArchived}&IncludeOnlyArchived=${IncludeOnlyArchived}&pageCount=${pageCount}`;

    return this._http.get<PaginationModel<ReplacementObjectModel[]>>(
      `${environment.url}${request}`
    );
  }

  public getById(id: string): Observable<ReplacementObjectAdminModel> {
    const request = ApiConfig.replacementObject.byId;

    return this._http.get<ReplacementObjectAdminModel>(
      `${environment.url}${request}?id=${id}`
    );
  }

  public getByName(name: string): Observable<ReplacementObjectAdminModel> {
    const request = ApiConfig.replacementObject.byName;

    return this._http.get<ReplacementObjectAdminModel>(
      `${environment.url}${request}?name=${name}`
    );
  }
}
