import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TokenModel } from "../models";
import { ApiConfig } from "../config/api.config";
import { environment } from "src/environments/environment";
@Injectable()
export class AuthService {
  constructor(private _http: HttpClient) {}

  public login(email: string, password: string): Observable<TokenModel> {
    const request = ApiConfig.auth.login;

    return this._http.post<TokenModel>(`${environment.url}${request}`, {
      email,
      password,
    });
  }
}
