<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form
                #customStylesForm="ngForm"
                cForm
                [formGroup]="form"
                [validated]="isSubmit && form.invalid"
              >
                <h1>Login</h1>
                <p class="text-medium-emphasis">Sign In to your account</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input
                    autoComplete="email"
                    required
                    cFormControl
                    [valid]="
                      isSubmit && form.get('email')?.valid
                        ? true
                        : isSubmit && form.get('email')?.invalid
                        ? false
                        : undefined
                    "
                    placeholder="Email"
                    formControlName="email"
                  />
                  <c-form-feedback [valid]="false"
                    >Invalid data</c-form-feedback
                  >
                </c-input-group>
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input
                    autoComplete="current-password"
                    cFormControl
                    required
                    [valid]="
                      isSubmit && form.get('password')?.valid
                        ? true
                        : isSubmit && form.get('password')?.invalid
                        ? false
                        : undefined
                    "
                    placeholder="Password"
                    type="password"
                    formControlName="password"
                  />
                  <c-form-feedback [valid]="false"
                    >Invalid password</c-form-feedback
                  >
                </c-input-group>
                <c-row>
                  <c-col xs="6">
                    <button
                      cButton
                      class="px-4"
                      color="primary"
                      (click)="login()"
                    >
                      Login
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
