import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ELanguage } from "../enums/language.enum";
import { ESortTypes } from "../enums/sort-types.enum";
import { Observable } from "rxjs";
import { PaginationModel } from "../models/pagination.model";
import { OfferModel } from "../models/offers/offer.model";
import { ApiConfig } from "../config/api.config";
import { environment } from "../../../environments/environment";
import {
  ApplicationResultModel,
  CreateOfferCommandModel,
  OfferAdminModel,
  SetVisaCardsToOfferModel,
  UpdateOfferCommandModel,
} from "../models";

@Injectable()
export class OfferService {
  constructor(private _http: HttpClient) {}

  public createOffer(
    data: CreateOfferCommandModel
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.create;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public updateOffer(
    data: UpdateOfferCommandModel
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.update;

    return this._http.put<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }

  public toggleSpecial(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.toggleSpecial;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id }
    );
  }

  public getByNameAz(name: string): Observable<OfferAdminModel> {
    const request = `${ApiConfig.offers.byName}?Language=1&name=${name}`;

    return this._http.get<OfferAdminModel>(`${environment.url}${request}`);
  }

  public getByNameRu(name: string): Observable<OfferAdminModel> {
    const request = `${ApiConfig.offers.byName}?Language=0&name=${name}`;

    return this._http.get<OfferAdminModel>(`${environment.url}${request}`);
  }

  public archive(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.archive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id }
    );
  }

  public unarchive(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.unarchive;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      { id }
    );
  }

  public setImages(
    id: string,
    ruImage: File,
    azImage: File
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.setImages;
    const fd = new FormData();
    fd.append("id", id);
    fd.append("ruImage", ruImage);
    fd.append("azImage", azImage);

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      fd
    );
  }

  public setRuImage(
    id: string,
    image: File
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.setImage;
    const fd = new FormData();
    fd.append("id", id);
    fd.append("image", image);
    fd.append("Language", `${ELanguage["russian"]}`);

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      fd
    );
  }

  public setAzImage(
    id: string,
    image: File
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.setImage;
    const fd = new FormData();
    fd.append("id", id);
    fd.append("image", image);
    fd.append("Language", `${ELanguage["azerbaijani"]}`);

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      fd
    );
  }

  public getOffers(
    pageNumber: number,
    pageSize: number,
    pageCount: number,
    search: string,
    language: ELanguage,
    IncludeOnlyArchived: boolean,
    includeArchived: boolean,
    includeSpecial: boolean,
    includeGeneral: boolean,
    includeOnlyDiscount: boolean,
    includeOnlyNew: boolean,
    includeOnlyTop: boolean,
    cardIds: string[],
    categoryIds: string[],
    brandId: string,
    isSortedDesc: boolean,
    sordBy: ESortTypes = ESortTypes["date"]
  ): Observable<PaginationModel<OfferModel[]>> {
    let request = ApiConfig.offers.get;

    if (search) {
      request = `${request}?search=${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    } else {
      request = `${request}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }

    request = `${request}&pageCount=${pageCount}&includeSpecial=${includeSpecial}&includeArchived=${includeArchived}&language=${language}&includeGeneral=${includeGeneral}&includeOnlyTop=${includeOnlyTop}&includeOnlyDiscount=${includeOnlyDiscount}&IncludeOnlyArchived=${IncludeOnlyArchived}&includeOnlyNew=${includeOnlyNew}${
      cardIds.length > 0 ? `&cardIds=${cardIds.join(",")}` : ""
    }${
      brandId ? `&brandId=${brandId}` : ""
    }&sortedBy=${sordBy}&isSortedDesc=${isSortedDesc}`;

    categoryIds.forEach((id) => {
      request = `${request}&categoryIds=${id}`
    })

    return this._http.get<PaginationModel<OfferModel[]>>(
      `${environment.url}${request}`
    );
  }

  public getById(id: string): Observable<OfferModel> {
    const request = `${ApiConfig.offers.byId}?id=${id}`;

    return this._http.get<OfferModel>(`${environment.url}${request}`);
  }

  public resetImages(id: string): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.resetImages;

    return this._http.post<ApplicationResultModel>(`${environment.url}${request}`, {id});
  }

  public setVisaCards(
    data: SetVisaCardsToOfferModel
  ): Observable<ApplicationResultModel> {
    const request = ApiConfig.offers.setVisaCard;

    return this._http.post<ApplicationResultModel>(
      `${environment.url}${request}`,
      data
    );
  }
}
