import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Location } from "@angular/common";

@Injectable({ providedIn: "root" })
export class NavHelper {
  private _currentQueryParams: Params;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location
  ) {
    this._route.queryParams.subscribe((params) => {
      this._currentQueryParams = params;
    });
  }

  public replaceLocationState(url: string): void {
    this._location.replaceState(url);
  }

  public getCurrentUrl(): string {
    return this._location.path();
  }

  public getCurrentUrlWithoutParams(): string {
    return this._route.snapshot.url.map((segment) => segment.path).join("/");
  }

  public removeQueryParam(paramName: string) {
    const url = this.getCurrentUrl();

    if (url.indexOf("?") === -1 && url.indexOf("&") === -1) {
      return url; // Если нет параметров, возвращаем исходный URL без изменений
    }

    const [baseUrl, queryString] = url.split("?");

    const parameters = queryString.split("&");

    const filteredParameters = parameters.filter((param) => {
      const [name] = param.split("=");
      return name !== paramName;
    });

    const newUrl =
      baseUrl +
      (filteredParameters.length > 0 ? "?" + filteredParameters.join("&") : "");
    this.replaceLocationState(newUrl);
    return newUrl;
  }

  public changeQueryParam(
    paramName: string[],
    newParamValue: string[]
  ): string {
    let currentURL = this.getCurrentUrl();

    for (let i = 0; i < paramName.length; i++) {
      const regex = new RegExp("([?&])" + paramName[i] + "=[^&]*", "i");

      if (currentURL.match(regex)) {
        currentURL = currentURL.replace(
          regex,
          "$1" + paramName[i] + "=" + newParamValue[i]
        );
      } else {
        const separator = currentURL.includes("?") ? "&" : "?";
        currentURL =
          currentURL + separator + paramName[i] + "=" + newParamValue[i];
      }
    }

    this.replaceLocationState(currentURL);
    return currentURL;
  }

  public getQueryParamsByKey(key: string): string {
    return this._currentQueryParams[key];
  }

  public goToBrand(id: string, archive: boolean): void {
    if (archive) {
      this._router.navigate([`brands/${id}`]);
    } else {
      this._router.navigate([`brands/${id}`]);
    }
  }

  public goToOffer(id: string): void {
    this._router.navigate([`offers/${id}`]);
  }

  public goToOffers(): void {
    this._router.navigate(["offers"]);
  }

  public goToBrands(): void {
    this._router.navigate(["brands"]);
  }

  public goToLogin(): void {
    this._router.navigate(["login"]);
  }
}
