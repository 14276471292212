import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({providedIn: 'root'})
export class ConfirmModalManager {
    public isShowSubj: Subject<boolean> = new Subject();
    public confirmFuntion: Function;
    public cancelFuntion: Function;
    public toggle(state: boolean) {        
        this.isShowSubj.next(state);
    }

    public async wait(): Promise<void> {
        return await new Promise((resolve, reject) => {            
            this.confirmFuntion = resolve;
            this.cancelFuntion = reject;
        });
    }

    get confirm(): Function {
        return this.confirmFuntion;
    }

    get cancel(): Function {
        return this.cancelFuntion;
    }
}