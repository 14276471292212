export class ApiConfig {
  static brands = {
    get: "/api/Brands/admin/get",
    byid: "/api/Brands/admin/get-by-id",
    acrhive: "/api/Brands/admin/archive",
    unarchive: "/api/Brands/admin/unarchive",
    create: "/api/Brands/admin/create",
    update: "/api/Brands/admin/update",
    getByName: "/api/Brands/admin/get-by-name",
    setImage: "/api/Brands/admin/set-image",
  };

  static support = {
    get: '/api/Support/admin/get',
  }

  static replacementObject = {
    get: "/api/ReplacementObjects/admin/get",
    byName: "/api/ReplacementObjects/admin/get-by-name",
    byId: "/api/ReplacementObjects/admin/get-by-id",
  }

  static building = {
    get: "/api/Buildings/admin/get",
    create: "/api/Buildings/admin/create",
    byId: "/api/Buildings/admin/get-by-id",
    update: "/api/Buildings/admin/update",
    archive: "/api/Buildings/admin/archive",
    unarchive: "/api/Buildings/admin/unarchive",
  };

  static visaCards = {
    get: "/api/VisaCards/admin/get",
  };

  static auth = {
    login: "/api/Authentication/login",
  };

  static categories = {
    get: "/api/Categories/admin/get",
    create: '/api/Categories/admin/create',
    byId: '/api/Categories/admin/get-by-id',
    byName: '/api/Categories/admin/get-by-name',
    update: '/api/Categories/admin/update',
    setImage: '/api/Categories/admin/set-image',
    archive: '/api/Categories/admin/archive',
    unarchive: '/api/Categories/admin/unarchive'
  };

  static banners = {
    get: "/api/Banners/admin/get",
    create: "/api/Banners/admin/create",
    byId: "/api/Banners/admin/get-by-id",
    update: "/api/Banners/admin/update",
    archive: "/api/Banners/admin/archive",
    unarchive: "/api/Banners/admin/unarchive",
    delete: '/api/Banners/admin/delete',
  };

  static offers = {
    get: "/api/Offers/admin/get",
    byId: "/api/Offers/admin/get-by-id",
    byName: "/api/Offers/admin/get-by-name",
    create: "/api/Offers/admin/create",
    update: "/api/Offers/admin/update",
    setImages: "/api/Offers/admin/set-images",
    setImage: "/api/Offers/admin/set-image",
    archive: "/api/Offers/admin/archive",
    unarchive: "/api/Offers/admin/unarchive",
    toggleSpecial: "/api/Offers/admin/change-special",
    setVisaCard: "/api/Offers/admin/set-cards",
    resetImages: '/api/Offers/admin/reset-images',
  };
}
