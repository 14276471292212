import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class PremuimManager {
  public isCommon: boolean = false;
  public isPremium: boolean = false;
  constructor() {}

  public toggleCommon(state: boolean): void {
    this.isCommon = state;
    this.isPremium = !state;
  }

  public togglePremium(state: boolean): void {
    this.isPremium = state;
    this.isCommon = !state;
  }
}
